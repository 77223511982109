import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { datadogRum } from "@datadog/browser-rum";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Job } from "./components";
import User from "layouts/User";

import { urls, mimeType, httpCodes } from "constants/urls";
import { Loading } from "shared/Loading";

const UserJob = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [jobData, setJobData] = useState({});
  const [printerData, setPrinterData] = useState({});

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${urls.jobs}/${id}`, {
      method: "GET",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ data, included }) => {
        setJobData(data);
        setPrinterData(included.find((include) => include.type === "printers"));
        setLoading(false);
      })
      .catch((response) => {
        datadogRum.addError(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  }, [id, navigate]);

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
        sx={{ p: theme.spacing(4) }}
      >
        <Grid container spacing={6}>
          <Grid item container>
            <Typography variant={"h4"}>Job</Typography>
          </Grid>
          <Grid item container>
            {loading ? (
              <Loading />
            ) : (
              <Job jobData={jobData} printerData={printerData} isMd={isMd} />
            )}
          </Grid>
        </Grid>
      </Box>
    </User>
  );
};

export default UserJob;
