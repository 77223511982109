import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";

import Container from "components/Container";

const mock = [
  {
    title: "Wi-Fi printer",
    subtitle: "Model 8",
    price: "275",
    priceSubtitle: "One-time fee",
    features: [
      {
        title: "Unlimited printing",
        isIncluded: true,
      },
      {
        title: "Rich text formatting",
        isIncluded: true,
      },
      {
        title: "Whitelabeling",
        isIncluded: true,
      },
      {
        title: "Drop shipping",
        isIncluded: true,
      },
    ],
    isHighlighted: true,
    btnText: "Buy",
  },
  {
    title: "Professional",
    subtitle: "Monthly subscription",
    price: "10",
    priceSubtitle: "Recurrs monthly",
    features: [
      {
        title: "24/7 Support",
        isIncluded: true,
      },
      {
        title: "Dashboard access",
        isIncluded: true,
      },
      {
        title: "Firmware upgrades",
        isIncluded: true,
      },
      {
        title: "API integration support",
        isIncluded: true,
      },
    ],
    isHighlighted: false,
    btnText: "Buy",
  },
  {
    title: "Commercial",
    subtitle: "Annual subscription",
    price: "110",
    priceSubtitle: "Recurrs yearly",
    features: [
      {
        title: "24/7 Support",
        isIncluded: true,
      },
      {
        title: "Dashboard access",
        isIncluded: true,
      },
      {
        title: "Firmware upgrades",
        isIncluded: true,
      },
      {
        title: "API integration support",
        isIncluded: true,
      },
    ],
    isHighlighted: false,
    btnText: "Buy",
  },
];

const Pricing = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box marginBottom={4}>
        <Typography
          gutterBottom
          align={"center"}
          variant={"h4"}
          fontWeight={700}
        >
          Simple pricing
        </Typography>
        <Typography color={"text.secondary"} align={"center"} variant={"h6"}>
          Pair your printer with our flexible licensing options.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={Card}
              height={1}
              display={"flex"}
              flexDirection={"column"}
              boxShadow={0}
              border={`1px solid ${theme.palette.divider}`}
            >
              <CardContent
                sx={{
                  padding: { sm: 4 },
                }}
              >
                <Box
                  marginBottom={4}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography variant={"h6"} gutterBottom>
                    <Box component={"span"} fontWeight={600}>
                      {item.title}
                    </Box>
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    color={"text.secondary"}
                    gutterBottom
                  >
                    <Box component={"span"}>{item.subtitle}</Box>
                  </Typography>
                  <Box display={"flex"} alignItems={"flex-start"}>
                    <Typography variant={"h4"} color={"primary"}>
                      <Box
                        component={"span"}
                        fontWeight={600}
                        marginRight={1 / 2}
                      >
                        $
                      </Box>
                    </Typography>
                    <Typography variant={"h2"} color={"primary"} gutterBottom>
                      <Box component={"span"} fontWeight={600}>
                        {item.price}
                      </Box>
                    </Typography>
                  </Box>
                  <Typography variant={"subtitle2"} color={"text.secondary"}>
                    {item.priceSubtitle}
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  {item.features.map((feature, j) => (
                    <Grid item xs={12} key={j}>
                      <Typography
                        component={"p"}
                        align={"center"}
                        style={{
                          textDecoration: !feature.isIncluded
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {feature.title}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <Box flexGrow={1} />
              <CardActions sx={{ justifyContent: "flex-end", padding: 4 }}>
                <Button
                  size={"large"}
                  variant={item.isHighlighted ? "contained" : "outlined"}
                  component={RouterLink}
                  to={"/sign-in?intent=buy&source=eatabit"}
                >
                  {item.btnText}
                </Button>
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Pricing;
