/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { datadogRum } from "@datadog/browser-rum";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { red } from "@mui/material/colors";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

import { urls, mimeType, httpCodes } from "constants/urls";

const categories = [
  { value: "general", label: "General question" },
  { value: "printer", label: "Printer issue" },
  { value: "billing", label: "Billing issue" },
];
const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(1, "Please enter a valid name")
    .max(254, "Please enter a shorter name")
    .required("Please specify your name"),
  subject: yup
    .string()
    .trim()
    .max(254, "Please enter a shorter subject")
    .required("Please specify a subject"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  message: yup
    .string()
    .required("Please specify a message")
    .max(2048, "Please specify a shorter message"),
  rebooted: yup
    .bool()
    .nullable()
    .when("category", {
      is: (value) => value === "printer",
      then: (schema) => schema.oneOf([true], "Field must be checked"),
    }),
  category: yup
    .string()
    .trim()
    .oneOf(
      categories.map((category) => category.value),
      "Please select a category"
    )
    .required("Please select a category"),
  printerId: yup
    .string()
    .trim()
    .nullable()
    .when("category", {
      is: (value) => value === "printer",
      then: (schema) =>
        schema
          .min(5, "Please enter at least 5 characters of the printer ID")
          .max(36, "Please enter a valid printer ID")
          .required("Please specify your printer's ID"),
    }),
});

const Form = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const printerId = params.get("printerId") || "";

  const [printersIDs, setPrintersIds] = useState([]);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);

  const {
    id: userId,
    attributes: { email, first_name, last_name, company_name },
  } = JSON.parse(localStorage.getItem("user"));

  const initialValues = {
    name: `${first_name} ${last_name}`,
    email,
    printerId,
    message: "",
    rebooted: false,
    category: "",
    subject: "",
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const params = new URLSearchParams({
      per: 1000,
    });

    fetch(`${urls.clientPrinters}?${params}`, {
      method: "GET",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ included }) => {
        setPrintersIds(included.map((include) => include.id));
      })
      .catch((response) => {
        datadogRum.addError(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  }, [navigate]);

  const onSubmit = (values) => {
    const token = localStorage.getItem("token");
    const relationships = {
      user: {
        data: {
          id: userId,
          type: "users",
          attributes: {
            first_name,
            last_name,
            email,
            company_name: company_name || "unknown",
          },
        },
      },
    };

    if (values.printerId && values.printerId.length > 1) {
      Object.assign(relationships, {
        printer: {
          data: {
            type: "printers",
            id: values.printerId,
          },
        },
      });
    }

    const data = {
      data: {
        type: "tickets",
        attributes: {
          message: values.message,
          rebooted: values.rebooted,
          category: values.category,
          subject: values.subject,
        },
        relationships,
      },
    };

    fetch(urls.tickets, {
      method: "POST",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setSuccessAlertVisible(true);
          formik.resetForm();
          formik.setFieldValue("printerId", "");
          window.scrollTo(0, -100);
        }
        throw response;
      })
      .catch((response) => {
        datadogRum.addError(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box
        padding={{ xs: 3, sm: 6 }}
        width={1}
        component={Card}
        boxShadow={1}
        marginBottom={4}
      >
        <Typography variant={"h5"} paragraph sx={{ mb: theme.spacing(4) }}>
          Create a new ticket
        </Typography>
        <Alert
          onClose={() => {
            setSuccessAlertVisible(false);
          }}
          severity={"success"}
          sx={{
            display: successAlertVisible ? null : "none",
            mb: theme.spacing(4),
          }}
        >
          <AlertTitle sx={{ fontWeight: 800 }}>Ticket created</AlertTitle>
          We will get back to you within 1 business day.
        </Alert>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Name"
                type="name"
                variant="outlined"
                color="primary"
                size="medium"
                name="name"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Subject"
                type="subject"
                variant="outlined"
                color="primary"
                size="medium"
                name="subject"
                fullWidth
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
              >
                <InputLabel id="select-category-label">Category</InputLabel>
                <Select
                  labelId={"select-category-label"}
                  id={"category-select"}
                  value={formik.values.category}
                  label={"Category"}
                  onChange={(event) => {
                    event.preventDefault();
                    formik.setFieldValue("category", event.target.value);
                  }}
                >
                  {categories.map((category, i) => (
                    <MenuItem key={i} value={category.value}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.category && formik.errors.category}
                </FormHelperText>
              </FormControl>
            </Grid>
            {formik.values.category === "printer" ? (
              <Fragment>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id={"printerId"}
                    name={"printerId"}
                    options={printersIDs}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Printer ID"}
                        error={
                          formik.touched.printerId &&
                          Boolean(formik.errors.printerId)
                        }
                        helperText={
                          formik.touched.printerId && formik.errors.printerId
                        }
                      />
                    )}
                    fullWidth
                    value={formik.values.printerId}
                    onChange={(event, value) => {
                      event.preventDefault();
                      formik.setFieldValue("printerId", value);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value === "" || option === value
                    }
                    freeSolo
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"rebooted"}
                          id={"rebooted"}
                          checked={formik.values.rebooted}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            color:
                              formik.touched.rebooted &&
                              Boolean(formik.errors.rebooted)
                                ? red[800]
                                : "default",
                            "&.Mui-checked": {
                              color: "default",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          color={
                            formik.touched.rebooted &&
                            Boolean(formik.errors.rebooted)
                              ? red[600]
                              : "default"
                          }
                        >
                          I have rebooted the printer
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              </Fragment>
            ) : null}
            <Grid item xs={12}>
              <TextField
                label="Message"
                multiline
                rows={6}
                variant="outlined"
                color="primary"
                size="medium"
                name="message"
                fullWidth
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Grid>
            <Grid item container justifyContent={"center"} xs={12}>
              <Button
                sx={{ height: 54, minWidth: 150 }}
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <Typography color="text.secondary" align={"center"}>
          We'll get back to you in 1 business day.
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
