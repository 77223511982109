import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import User from "layouts/User";

import { Printers, Search } from "./components";
import { urls, mimeType, httpCodes } from "constants/urls";
import { per } from "constants/pagination";
import { Loading } from "shared/Loading";

const UserPrinters = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = React.useState({
    terms: "",
    page: 0,
    sortOn: "",
    sortDir: "",
    per,
  });
  const [loading, setLoading] = React.useState(true);
  const [printersData, setPrintersData] = React.useState([]);
  const [meta, setMeta] = React.useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    const params = new URLSearchParams({
      page: searchParams.page,
      terms: searchParams.terms,
      sort_on: searchParams.sortOn,
      sort_dir: searchParams.sortDir,
      per: searchParams.per,
    });

    fetch(`${urls.clientPrinters}?${params}`, {
      method: "GET",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ data, meta }) => {
        setPrintersData(data);
        setMeta(meta);
        setLoading(false);
      })
      .catch((response) => {
        datadogRum.addError(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  }, [searchParams, navigate]);

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
        sx={{ p: theme.spacing(4) }}
      >
        <Grid container spacing={6}>
          <Grid item container>
            <Typography variant={"h4"}>Printers</Typography>
          </Grid>
          <Grid item container>
            <Search
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Grid>
          <Grid item container>
            {loading ? (
              <Loading />
            ) : (
              <Printers
                printersData={printersData}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                meta={meta}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </User>
  );
};

export default UserPrinters;
