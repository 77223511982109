import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import { datadogRum } from "@datadog/browser-rum";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

import { urls, mimeType, httpCodes } from "constants/urls";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
});

const Form = ({
  userData: {
    id: userId,
    attributes: { first_name, last_name, email },
  },
  setUserData,
  isMd,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [alertVisible, setAlertVisible] = useState(false);

  const initialValues = {
    firstName: first_name,
    lastName: last_name,
    email,
  };

  const onSubmit = (values) => {
    const token = localStorage.getItem("token");
    const body = {
      data: {
        id: userId,
        type: "users",
        attributes: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
        },
      },
    };

    fetch(urls.user, {
      method: "PATCH",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        formik.setSubmitting(false);
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ data }) => {
        setUserData(data);
        setAlertVisible(true);
      })
      .catch((response) => {
        datadogRum.addError(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
        if (response.status === httpCodes.badRequest) {
          formik.setErrors({
            email: "Email address already taken",
          });
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const alert = () => {
    return (
      <Alert
        severity={"success"}
        sx={{ mb: theme.spacing(3) }}
        onClose={() => {
          setAlertVisible(!alertVisible);
        }}
      >
        <AlertTitle>Success</AlertTitle>
        User successfully updated.
      </Alert>
    );
  };

  return (
    <Box paddingTop={theme.spacing(4)}>
      {alertVisible ? alert() : null}
      <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First name *"
              variant="outlined"
              name={"firstName"}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last name *"
              variant="outlined"
              name={"lastName"}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"flex-end"}
              width={1}
              margin={"0 auto"}
            >
              <Button
                variant={"contained"}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(-1);
                }}
                sx={{ mr: theme.spacing(2) }}
                color={"secondary"}
                fullWidth={!isMd}
              >
                Back
              </Button>
              <LoadingButton
                loading={formik.isSubmitting}
                size={"large"}
                variant={"contained"}
                type={"submit"}
                sx={{ mt: isMd ? null : theme.spacing(2) }}
                fullWidth
              >
                Save
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  userData: PropTypes.shape({}).isRequired,
  setUserData: PropTypes.func.isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Form;
