import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import Page from "./components/Page";

import "react-lazy-load-image-component/src/effects/blur.css";
import "aos/dist/aos.css";

const {
  REACT_APP_HEAD,
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SERVICE,
  REACT_APP_ENVIRONMENT,
} = process.env;

if (REACT_APP_ENVIRONMENT !== "development") {
  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: REACT_APP_DATADOG_SERVICE,
    version: REACT_APP_HEAD,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    env: REACT_APP_ENVIRONMENT,
  });

  datadogRum.startSessionReplayRecording();
}

const App = () => {
  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
};

export default App;
