/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { datadogRum } from "@datadog/browser-rum";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { urls, mimeType, httpCodes } from "constants/urls";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

const Form = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const initialValues = {
    password: "",
  };

  const onSubmit = (values) => {
    fetch(`${urls.passwordResets}/${token}`, {
      method: "PATCH",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          navigate("/sign-in");
        }
      })
      .catch((response) => {
        datadogRum.addError(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Reset password
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          Set a new password
        </Typography>
        <Typography color="text.secondary">
          Enter a strong password that is at least 8 characters
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Button size={"large"} variant={"contained"} type={"submit"}>
                Save password
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
