import React, { useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { datadogRum } from "@datadog/browser-rum";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";

import { urls, mimeType } from "constants/urls";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
  companyName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid company name")
    .max(50, "Please enter a valid company name")
    .required("Please specify your company name"),
});

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(location.search);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyName: "",
  };

  const onSubmit = (values) => {
    setLoading(true);

    const data = {
      data: {
        type: "users",
        attributes: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          client_name: values.companyName,
        },
      },
      meta: Object.fromEntries(params),
    };

    fetch(urls.user, {
      method: "POST",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(({ jwt, user: { data: userData } }) => {
        localStorage.setItem("token", jwt);
        localStorage.setItem("user", JSON.stringify(userData));
        navigate("/user/cart");
      })
      .catch((response) => {
        response.text().then((errorMessage) => {
          const errorsData = JSON.parse(errorMessage);

          if (errorsData.errors.length > 0) {
            if (
              errorsData.errors[0].source.pointer === "/data/attributes/name"
            ) {
              formik.setErrors({
                companyName: errorsData.errors[0].detail,
              });
            }
            if (
              errorsData.errors[0].source.pointer === "/data/attributes/email"
            ) {
              formik.setErrors({
                email: errorsData.errors[0].detail,
              });
            }
          }
        });
        datadogRum.addError(response);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Signup
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Create an account
        </Typography>
        <Typography color="text.secondary">
          Fill out the form to get started.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your first name
            </Typography>
            <TextField
              label="First name *"
              variant="outlined"
              name={"firstName"}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={"lastName"}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your company name
            </Typography>
            <TextField
              label="Company name *"
              variant="outlined"
              name={"companyName"}
              fullWidth
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Already have an account?{" "}
                  <Link
                    component={RouterLink}
                    color={"primary"}
                    to={`/sign-in?${params.toString()}`}
                    underline={"none"}
                  >
                    Sign in.
                  </Link>
                </Typography>
              </Box>
              <LoadingButton
                loading={loading}
                size={"large"}
                variant={"contained"}
                type={"submit"}
              >
                Sign up
              </LoadingButton>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"text.secondary"}
              align={"center"}
            >
              By clicking "Sign up" button you agree with our{" "}
              <Link
                component={RouterLink}
                color={"primary"}
                to={"/terms"}
                underline={"none"}
              >
                company terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
